import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import {
  addAdminNews,
  getNewsPackageManagementForUserId,
  getNewsPackageOfUserId,
} from "../../Api/apiUrls";

function Add_news({ onClose }) {
  const [modalAdd_newsIsOpen, setAdd_newsModalIsOpen] = useState("");
  const [newsName, setNewsName] = useState("");
  const [newsDescription, setNewsDescription] = useState("");
  const [newsSummary, setNewsSummary] = useState("");
  const [newsAuthor, setNewsAuthor] = useState("");
  const [newsStatus, setNewsStatus] = useState("");
  const [file, setIconFile] = useState();
  const [isAddingnews, setIsAddingnews] = useState(false);
  const [newsKeyword, setNewsKeyword] = useState("");
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const closeAdd_news = () => {
    setAdd_newsModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  const handleStatusChange = (selectedOption) => {
    setNewsStatus(selectedOption.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIconFile(file);
    console.log(file);

    const formData = new FormData();
    formData.append("image", file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imgElement = document.getElementById("selectedImg");
      imgElement.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  async function addNews(
    newsName,
    file,
    newsDescription,
    newsSummary,
    newsStatus,
    selectedAuthor,
    newsKeyword
  ) {
    try {
      const formData = new FormData();

      formData.append("title", newsName);
      formData.append("type", "News");
      formData.append("photo", file);
      formData.append("discription", newsDescription);
      formData.append("keywords", newsKeyword);
      formData.append("shortSummery", newsSummary);
      formData.append("status", newsStatus);
      formData.append("news_author", selectedAuthor[0].value);
      formData.append("package", selectedPackage.value);
      const selectedPkgOption = packageOptions.find(
        (pkg) => pkg.value === selectedPackage.value
      );
      if (selectedPkgOption) {
        formData.append("order_id", selectedPkgOption.order_id); // Append the order_id
      } else {
        throw new Error("Selected package not found");
      }

      const response = await fetch(addAdminNews, {
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      if (response.ok) {
        if (responseData.status === 200){
          return true;
        }else{
          return false;
        }
      } else {
        throw new Error("Failed to add News");
      }
    } catch (error) {
      console.error("Error adding News:", error);
      return false;
    }
  }

  const handleAddNews = async () => {
    if (!newsName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingnews(true);

    const result = await addNews(
      newsName,
      file,
      newsDescription,
      newsSummary,
      newsStatus,
      selectedAuthor,
      newsKeyword
    );
    setIsAddingnews(false);
    if (result) {
      alert("News Added Successfully");
      console.log(newsStatus);
      window.location.reload();
      onClose();
    } else {
      alert("Error Adding News");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    fetch(`${getNewsPackageManagementForUserId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data); // Log API response for debugging

        if (data.success) {
          if (Array.isArray(data.data)) {
            setNewsAuthor(data.data);
          } else {
            console.error("Authors data is not an array:", data.data);
          }
        } else {
          console.error("Error fetching authors:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching authors:", error);
      });
  }, []);

  const authorOptions = Array.isArray(newsAuthor)
    ? newsAuthor.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : [];

  console.log("Author Options:", authorOptions);

  const [selectedAuthor, setSelectedAuthor] = useState([]);

  const handleAuthorChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedAuthor([selectedOptions]);
  };

  const [packageOptions, setPackageOptions] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    if (selectedAuthor.length > 0) {
      // Check if an author is selected
      // Fetch news package data for the selected author
      fetch(`${getNewsPackageOfUserId}${selectedAuthor[0].value}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("News Package API Response:", data); // Log API response for debugging

          if (data.success) {
            // Sort the packages by ID and then by name
            const sortedData = data.data.sort((a, b) => {
              if (a.id !== b.id) {
                return a.id - b.id;
              } else {
                return a.name.localeCompare(b.name);
              }
            });

            const options = sortedData.map((pkg) => ({
              value: pkg.id,
              order_id: pkg.order_id, // Store order_id
              label: (
                <span>
                  {pkg.name}-
                  <span className="" style={{ fontSize: "12px" }}>
                    {pkg.order_id}({pkg.duration})
                  </span>{" "}
                </span>
              ),
            }));
            setPackageOptions(options);
          } else {
            console.error("Error fetching news package:", data.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching news package:", error);
        });
    }
  }, [selectedAuthor]);
  // Run effect whenever selectedAuthor changes
  // Empty dependency array to run effect only once

  // Function to handle select change
  const handlePackageChange = (selectedOption) => {
    setSelectedPackage(selectedOption);
  };

  return (
    <div>
      {modalAdd_newsIsOpen && <add_news onClose={closeAdd_news} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add News</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newsName}
                    onChange={(e) => setNewsName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Featured Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={newsDescription}
                    onChange={(e) => setNewsDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    keywords<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newsKeyword}
                    onChange={(e) => setNewsKeyword(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    One Line Summary<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=" "
                    value={newsSummary}
                    onChange={(e) => setNewsSummary(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Author <font color="red"></font>
                  </label>

                  <Select
                    options={authorOptions}
                    //  value={selectedCategories}
                    value={selectedAuthor.length > 0 ? selectedAuthor[0] : null}
                    onChange={handleAuthorChange}
                  ></Select>
                </div>
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputFullName">
                    Select Package <font color="red">*</font>
                  </label>
                  <Select
                    options={packageOptions}
                    value={selectedPackage}
                    onChange={handlePackageChange}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status:<font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      options={status}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingnews === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddNews}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_news;
