import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_Instructor_Workshop from "../../Components/instructor_comp/add_workshop.js";
import Update_Instructor_Workshop from "../../Components/instructor_comp/update_workshop.js";
import Delete_Instructor_Workshop from "../../Components/instructor_comp/delete_workshop.js";
import Workshop_booking_slots from "../../Components/instructor_comp/Workshop_booking_slots.js";
import Instructor_Packages from "../../Pages/instructor/packages.js";
import PackagePopup from "../../Components/instructor_comp/packagePopup.js";
import WorkshopReview from "../../Components/Admin_components/workshopReview.js";
import WorkshopWishlist from "../../Components/Admin_components/workshopWishlist.js";
import WorkshopTotalAppointment from "../../Components/Admin_components/workshopTotalAppointment.js";
import WorkshopActiveAppointment from "../../Components/Admin_components/workshopActiveAppointment.js";
import "../../Api/apiUrls.js";
import { getInstructorWorkshop } from "../../Api/apiUrls";
import { Link } from "react-router-dom";
function Instructor_Workshop() {
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [filteredWorkshopData, setFilteredWorkshopData] = useState([]);
  const [isToggled, setIsToggled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [workshopData, setWorkshopData] = useState([]);
  const [modalPackagePopupIsOpen, setPackagePopupIsOpen] = useState("");
  const [modalWorkshopReviewIsOpen, setWorkshopReviewModalIsOpen] =
    useState("");
  const [workshopSelectedDescription, setSelectedWorkshopDescription] =
    useState(null);
  const [
    workshopSelectedShortDescription,
    setSelectedWorkshopShortDescription,
  ] = useState(null);
  const [workshopSelectedName, setSelectedWorkshopName] = useState(null);
  const [workshopSelectedKnowledge, setSelectedWorkshopKnowledge] =
    useState(null);

  const [workshopSelectedWhatYouGet, setSelectedWorkshopWhatYouGet] =
    useState(null);
  const [workshopSelectedWhatBring, setSelectedWorkshopWhatBring] =
    useState(null);
  const [workshopSelectedSuitable, setSelectedWorkshopSuitable] =
    useState(null);
  const [workshopSelectedSeats, setSelectedWorkshopSeats] = useState(null);
  const [workshopSelectedDuration, setSelectedWorkshopDuration] =
    useState(null);
  const [workshopSelectedPrice, setSelectedWorkshopPrice] = useState(null);
  const [workshopSelectedAddress, setSelectedWorkshopAddress] = useState(null);
  const [workshopSelectedPostalCode, setSelectedWorkshopPostalCode] =
    useState(null);
  const [workshopSelectedGoogleCode, setSelectedWorkshopGoogleCode] =
    useState(null);
  const [workshopSelectedEmail, setSelectedWorkshopEmail] = useState(null);
  const [workshopSelectedPhone, setSelectedWorkshopPhone] = useState(null);
  const [workshopSelectedWebsite, setSelectedWorkshopWebsite] = useState(null);
  const [workshopSelectedCategory, setSelectedWorkshopCategory] =
    useState(null);
  const [workshopSelectedCity, setSelectedWorkshopCity] = useState(null);
  const [workshopSelectedLocation, setSelectedWorkshopLocation] =
    useState(null);
  const [workshopSelectedSubCategory, setSelectedWorkshopSubCategory] =
    useState(null);
  const [workshopSelectedStatus, setWorkshopSelectedStatus] = useState(null);
  const [workshopSelectedTherapy, setWorkshopSelectedTherapy] = useState(null);
  const [workshopSelectedOutstation, setWorkshopSelectedOutstation] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [workshopSelectedOnline, setWorkshopSelectedOnline] = useState(null);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem('hasReloaded');
  //   if (!hasReloaded) {
  //     // alert("hiiiiiiiiiii")
  //     localStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();
  //   }
  // }, []);
  useEffect(() => {
    const filteredData = workshopData.filter((workshop) => {
      const isFilter =
        !selectedFilter ||
        selectedFilter.value === "All" ||
        workshop.filter === selectedFilter.value;
      const isStatus =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        workshop.status === selectedStatus.value;
      const isMonth =
        !selectedMonth ||
        selectedMonth.value === "All" ||
        workshop.created_at_month === selectedMonth.value;
      const isYear =
        !selectedYear ||
        selectedYear.value === "All" ||
        workshop.created_at_year === selectedYear.value;

      const values = Object.values(workshop).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isFilter && isStatus && isMonth && isYear && isSearchMatch;
    });

    setFilteredWorkshopData(filteredData);
  }, [
    selectedFilter,
    selectedStatus,
    selectedYear,
    selectedMonth,
    workshopData,
    searchQuery,
  ]);

  
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const [modalWorkshop_booking_slotsIsOpen, setWorkshop_booking_slotsIsOpen] =
    useState("");
  const [
    modalAdd_Instructor_WorkshopIsOpen,
    setAdd_Instructor_WorkshopModalIsOpen,
  ] = useState("");
  const [modalInstructor_PackagesIsOpen, setInstructor_PackagesModalIsOpen] =
    useState(false);
  const [
    modalUpdate_Instructor_WorkshopIsOpen,
    setUpdate_Instructor_WorkshopModalIsOpen,
  ] = useState(false);
  const [
    modalDelete_Instructor_WorkshopIsOpen,
    setDelete_Instructor_WorkshopIsOpen,
  ] = useState(false);

  const openWorkshopReview = (id) => {
    setId(id);
    setWorkshopReviewModalIsOpen(true);
  };

  const closeWorkshopReview = () => {
    setWorkshopReviewModalIsOpen(false);
  };

  const [modalWorkshopWishlistIsOpen, setWorkshopWishlistModalIsOpen] =
    useState("");

  const openWorkshopWishlist = (id) => {
    setId(id);
    setWorkshopWishlistModalIsOpen(true);
  };

  const closeWorkshopWishlist = () => {
    setWorkshopWishlistModalIsOpen(false);
  };

  const [
    modalWorkshopTotalAppointmentIsOpen,
    setWorkshopTotalAppointmentModalIsOpen,
  ] = useState("");

  const openWorkshopTotalAppointment = (id) => {
    setId(id);
    setWorkshopTotalAppointmentModalIsOpen(true);
  };
  const closeWorkshopTotalAppointment = () => {
    setWorkshopTotalAppointmentModalIsOpen(false);
  };

  const [
    modalWorkshopActiveAppointmentIsOpen,
    setWorkshopActiveAppointmentModalIsOpen,
  ] = useState("");

  const openWorkshopActiveAppointment = (id) => {
    setId(id);
    setWorkshopActiveAppointmentModalIsOpen(true);
  };
  const closeWorkshopActiveAppointment = () => {
    setWorkshopActiveAppointmentModalIsOpen(false);
  };
  const [dateTime, setDateTime] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);

  const filter = [
    { value: "All", label: "All" },
    { value: "Featured", label: "Featured" },
    { value: "Sponsored", label: "Sponsored" },
    // { value: "Gift", label: "Gift" },
  ];
  const month = [
    { value: "All", label: "All" },
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const year = [
    { value: "All", label: "All" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const date = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    const formattedDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedDateTime;
  };
  const openAdd_Instructor_Workshop = () => {
    closeAdd_Instructor_Workshop();
    const currentDateTime = getCurrentDateTime();
    setDateTime(currentDateTime);
    setAdd_Instructor_WorkshopModalIsOpen(true);
  };

  const closeAdd_Instructor_Workshop = () => {
    setAdd_Instructor_WorkshopModalIsOpen(false);
  };
  const openInstructor_Packages = () => {
    closeInstructor_Packages();
    setInstructor_PackagesModalIsOpen(true);
  };

  const closeInstructor_Packages = () => {
    setInstructor_PackagesModalIsOpen(false);
  };
  const openDelete_Instructor_Workshop = (workshopId) => {
    setSelectedUserId(workshopId);
    closeDelete_Instructor_Workshop();
    setDelete_Instructor_WorkshopIsOpen(true);
  };

  const closeDelete_Instructor_Workshop = () => {
    setDelete_Instructor_WorkshopIsOpen(null);
  };
  const openWorkshop_booking_slots = (id) => {
    setId(id);
    setWorkshop_booking_slotsIsOpen(true);
  };
  const closeWorkshop_booking_slots = () => {
    setWorkshop_booking_slotsIsOpen(false);
  };
  const openUpdate_Instructor_Workshop = (
    workshopId,
    workshopName,
    workshopShort,
    workshopDescription,
    workshopKnowledge,
    workshopYouGet,
    workshopBring,
    workshopSuitable,
    wokshopCategory,
    wokshopSubCategory,
    workshopseats,
    workshopDuration,
    workshopPrice,
    workshopTherapy,
    workshopOutstation,
    workshopAddress,
    workshopCity,
    workshopLocation,
    workshopPostal,
    workshopGoogle,
    workshopEmail,
    workshopPhone,
    workshopWebsite,
    workshopStatus,
    workshopOnline
  ) => {
    setSelectedUserId(workshopId);
    setSelectedWorkshopName(workshopName);
    setSelectedWorkshopShortDescription(workshopShort);
    setSelectedWorkshopDescription(workshopDescription);
    setSelectedWorkshopKnowledge(workshopKnowledge);
    setSelectedWorkshopWhatYouGet(workshopYouGet);
    setSelectedWorkshopWhatBring(workshopBring);
    setSelectedWorkshopSuitable(workshopSuitable);
    setSelectedWorkshopCategory(wokshopCategory);
    setSelectedWorkshopSubCategory(wokshopSubCategory);
    setSelectedWorkshopSeats(workshopseats);
    setSelectedWorkshopDuration(workshopDuration);
    setSelectedWorkshopPrice(workshopPrice);
    setWorkshopSelectedTherapy(workshopTherapy);
    setWorkshopSelectedOutstation(workshopOutstation);
    setSelectedWorkshopAddress(workshopAddress);
    setSelectedWorkshopCity(workshopCity);
    setSelectedWorkshopLocation(workshopLocation);
    setSelectedWorkshopPostalCode(workshopPostal);
    setSelectedWorkshopGoogleCode(workshopGoogle);
    setSelectedWorkshopEmail(workshopEmail);
    setSelectedWorkshopPhone(workshopPhone);
    setSelectedWorkshopWebsite(workshopWebsite);
    setWorkshopSelectedStatus(workshopStatus);
    setWorkshopSelectedOnline(workshopOnline);
    closeUpdate_Instructor_Workshop();
    setUpdate_Instructor_WorkshopModalIsOpen(true);
  };

  const closeUpdate_Instructor_Workshop = () => {
    setUpdate_Instructor_WorkshopModalIsOpen(false);
  };

  const openPackagePopup = (id2) => {
    setId2(id2);
    setPackagePopupIsOpen(true);
  };

  const closePackagePopup = () => {
    setPackagePopupIsOpen(false);
  };

  const instructorId = localStorage.getItem("user_id");

  useEffect(() => {
    fetch(getInstructorWorkshop + instructorId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const workshop = data.data.Workshop;
        setWorkshopData(workshop);
        console.log(workshop);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const token = localStorage.getItem("token");
  const userStatus = localStorage.getItem("userStatus");


  // useEffect(() => {
        
  //   const handleBeforeUnload = (event) => {
  //     // Custom confirmation dialog
  //     const confirmationMessage = 'You may lose form data. Are you sure you want to leave?';
  //     const userConfirmed = window.confirm(confirmationMessage);

  //     if (setAdd_Instructor_WorkshopModalIsOpen === true) {
  //       // Prevent page from refreshing
  //       console.log("🚀 ~ handleBeforeUnload ~ setAdd_Instructor_WorkshopModalIsOpen:", setAdd_Instructor_WorkshopModalIsOpen)
 
  //     }
  //     if (!userConfirmed ) {
  //       // Prevent page from refreshing
     
  //       event.preventDefault();
  //     } else {
  //       // Allow the page to refresh
  //       window.location.reload();
  //     }
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []); 

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (setAdd_Instructor_WorkshopModalIsOpen == true) {
  //       console.log("🚀 ~ handleBeforeUnload ~ setAdd_Instructor_WorkshopModalIsOpen:", setAdd_Instructor_WorkshopModalIsOpen)
        
  //       event.preventDefault();
  //       event.returnValue = ''; // This triggers the browser's default alert dialog
  //     }
  //   };

  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [setAdd_Instructor_WorkshopModalIsOpen]);

  return (
    <div>
      <Header></Header>
      {modalAdd_Instructor_WorkshopIsOpen && (
        <Add_Instructor_Workshop
          onClose={closeAdd_Instructor_Workshop}
          dateTime={dateTime}
        />
      )}
      {modalWorkshopReviewIsOpen && (
        <WorkshopReview onClose={closeWorkshopReview} id={id} />
      )}

      {modalWorkshopWishlistIsOpen && (
        <WorkshopWishlist onClose={closeWorkshopWishlist} id={id} />
      )}
      {modalWorkshopTotalAppointmentIsOpen && (
        <WorkshopTotalAppointment
          onClose={closeWorkshopTotalAppointment}
          id={id}
        />
      )}
      {modalWorkshopActiveAppointmentIsOpen && (
        <WorkshopActiveAppointment
          onClose={closeWorkshopActiveAppointment}
          id={id}
        />
      )}
      {modalPackagePopupIsOpen && (
        <PackagePopup onClose={closePackagePopup} id2={id2} />
      )}
      {modalUpdate_Instructor_WorkshopIsOpen && (
        <Update_Instructor_Workshop
          workshopId={selectedUserId}
          workshopName={workshopSelectedName}
          workshopShort={workshopSelectedShortDescription}
          workshopDescription={workshopSelectedDescription}
          workshopKnowledge={workshopSelectedKnowledge}
          workshopYouGet={workshopSelectedWhatYouGet}
          workshopBring={workshopSelectedWhatBring}
          workshopSuitable={workshopSelectedSuitable}
          wokshopCategory={workshopSelectedCategory}
          wokshopSubCategory={workshopSelectedSubCategory}
          workshopseats={workshopSelectedSeats}
          workshopDuration={workshopSelectedDuration}
          workshopPrice={workshopSelectedPrice}
          workshopTherapy={workshopSelectedTherapy}
          workshopOutstation={workshopSelectedOutstation}
          workshopAddress={workshopSelectedAddress}
          workshopCity={workshopSelectedCity}
          workshopLocation={workshopSelectedLocation}
          workshopPostal={workshopSelectedPostalCode}
          workshopGoogle={workshopSelectedGoogleCode}
          workshopEmail={workshopSelectedEmail}
          workshopPhone={workshopSelectedPhone}
          workshopWebsite={workshopSelectedWebsite}
          workshopStatus={workshopSelectedStatus}
          workshopOnline={workshopSelectedOnline}
          onClose={closeUpdate_Instructor_Workshop}
        />
      )}
      {modalDelete_Instructor_WorkshopIsOpen && (
        <Delete_Instructor_Workshop
          workshopId={selectedUserId}
          onClose={closeDelete_Instructor_Workshop}
        />
      )}
      {modalWorkshop_booking_slotsIsOpen && (
        <Workshop_booking_slots onClose={closeWorkshop_booking_slots} id={id} />
      )}
      {/* {modalInstructor_PackagesIsOpen && (
        <Instructor_Packages onClose={closeInstructor_Packages} />
      )} */}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Workshop</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Workshop</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="col-12 col-md-12 col-lg-12 row">
              <div class="child col-md-2" style={{ padding: "5px" }}>
                <input
                  id="myInput"
                  type="text"
                  style={{
                    borderRadius: "5px",
                    height: "37px",
                    padding: "10px",
                    border: "1px solid lightgrey ",
                    width: "100%",
                  }}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  placeholder="Search..."
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <Select
                  options={filter}
                  placeholder="Filter By"
                  onChange={(selectedOption) =>
                    setSelectedFilter(selectedOption)
                  }
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <Select
                  options={status}
                  placeholder="Select status"
                  onChange={(selectedOption) =>
                    setSelectedStatus(selectedOption)
                  }
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <Select
                  options={month}
                  placeholder="Select Month"
                  onChange={(selectedOption) =>
                    setSelectedMonth(selectedOption)
                  }
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <Select
                  options={year}
                  placeholder="Select Year"
                  onChange={(selectedOption) => setSelectedYear(selectedOption)}
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <a>
                  <button
                    id="btnNewUser"
                    type="button"
                    data-toggle="modal"
                    data-target="#modalAdd"
                    className="btn-round"
                    // onClick={() => {
                    //   if (userStatus === "Active") {
                    //     openAdd_Instructor_Workshop();
                    //   } else {
                    //     alert("You must buy a package to add a workshop.");
                    //     document.getElementById('redirectLink').click(); // Programmatically trigger Link
                    //   }
                    // }}

                    onClick={openAdd_Instructor_Workshop}
                  >
                    <i className="bi bi-person-add"></i> Add Workshop
                  </button>
                </a>

                {/* Hidden Link for redirecting */}
                <Link
                  id="redirectLink"
                  to={{
                    pathname: "/instructor/profile",
                    search: `?token=${token}`,
                  }}
                  style={{ display: 'none' }}
                />
              </div>

            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : filteredWorkshopData.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>

                      <th scope="col">Slots</th>
                      <th scope="col">Status</th>
                      <th scope="col">Workshop Name</th>
                      {/* <th scope="col">Instructor</th> */}
                      <th scope="col">Rating</th>
                      <th scope="col">Reviews</th>
                      <th scope="col">Share</th>
                      <th scope="col">Visit</th>
                      <th scope="col">Wishlisted</th>
                      <th scope="col">Total Appointments</th>
                      <th scope="col">Appointment Active</th>
                      <th scope="col">Package Type</th>
                      <th scope="col">Status Of Package</th>
                      <th scope="col">Outstation</th>
                      <th scope="col">Therapy</th>
                      <th scope="col">Online</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredWorkshopData.map((workshop, index) => (
                      <tr key={workshop.id}>
                        <th scope="row">{index + 1}</th>
                        {/* <td>{workshop.id}</td> */}

                        <td>
                          <div className="social-links mt-2">
                            <a
                              onClick={() =>
                                openWorkshop_booking_slots(workshop.id)
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                          </div>
                        </td>
                        <td>{workshop.status}</td>
                        <td>{workshop.name}</td>
                        {/* <td>
                          <b>
                            {workshop.workshopInstructor
                              ? workshop.workshopInstructor.name
                              : "-"}
                          </b>
                          <br />
                          <b>
                            {workshop.workshopInstructor
                              ? workshop.workshopInstructor.email
                              : "-"}
                          </b>
                          <br />
                          <b>
                            {workshop.workshopInstructor
                              ? workshop.workshopInstructor.mobileNumber
                              : "-"}
                          </b>
                        </td> */}
                        <td>{workshop.ratings_count}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openWorkshopReview(workshop.id)}
                          >
                            {workshop.reviews_count}
                          </a>
                        </td>
                        <td>{workshop.share_count}</td>
                        <td>{workshop.visit}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openWorkshopWishlist(workshop.id)}
                          >
                            {workshop.wishlist_count}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openWorkshopTotalAppointment(workshop.id)
                            }
                          >
                            {workshop.total_appointments}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openWorkshopActiveAppointment(workshop.id)
                            }
                          >
                            {workshop.active_appointments}
                          </a>
                        </td>
                        <td>
                          {workshop.package ? workshop.package.duration : "-"}
                        </td>
                        <td>
                          {workshop.package ? workshop.package.status : "-"}
                        </td>
                        <td>{workshop.outstation}</td>
                        <td>{workshop.therapy}</td>
                        <td>{workshop.online}</td>
                        <td>{formatCreatedAt(workshop.created_at)}</td>

                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_Instructor_Workshop(
                                  workshop.id,
                                  workshop.name,
                                  workshop.short_description,
                                  workshop.description,
                                  workshop.knowledge_require,
                                  workshop.what_you_will_get,
                                  workshop.what_to_bring,
                                  workshop.suitable_for,
                                  {
                                    value: workshop.category.id,
                                    label: workshop.category.name,
                                  },
                                  {
                                    value: workshop.subcategory.id,
                                    label: workshop.subcategory.name,
                                  },
                                  workshop.total_seats,
                                  workshop.duration_in_hour,
                                  workshop.price,
                                  {
                                    value: workshop.id,
                                    label: workshop.therapy,
                                  },
                                  {
                                    value: workshop.id,
                                    label: workshop.outstation,
                                  },
                                  workshop.address,
                                  {
                                    value: workshop.city.id,
                                    label: workshop.city.name,
                                  },
                                  {
                                    value: workshop.area.id,
                                    label: workshop.area.name,
                                  },
                                  workshop.pincode,
                                  workshop.google_location_link,
                                  workshop.email,
                                  workshop.mobileNumber,
                                  workshop.website,
                                  {
                                    value: workshop.id,
                                    label: workshop.status,
                                  },
                                  {
                                    value: workshop.id,
                                    label: workshop.online,
                                  }
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            {/* <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openDelete_Instructor_Workshop(workshop.id)
                              }
                            >
                              <i class="bi bi-trash3"></i>
                            </a> */}
                            &nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openPackagePopup(workshop.id)}
                            >
                              <i class="bi bi-box-arrow-in-up"></i>
                            </a>
                            &nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredWorkshopData.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor_Workshop;
