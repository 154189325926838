import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import "../../Api/apiUrls";
import $ from 'jquery';
import { API_URL } from "../../Api/apiUrls";
import GenerateInvoice from "../../Components/instructor_comp/generateInvoice.js";

function Invoice() {

  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const instructorId = localStorage.getItem("user_id");
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [modalGenerateInvoiceIsOpen, setGenerateInvoiceIsOpen] = useState("");

  const openGenerateInvoice = (slotId) => {
    setSelectedSlotId(slotId);
    closeGenerateInvoice();
    setGenerateInvoiceIsOpen(true);
  };

  const closeGenerateInvoice = () => {
      setGenerateInvoiceIsOpen(false);
  };


  useEffect(() => {
    // Initialize Select2 for all elements
    if ($('#workshopSelect').length) {
      $('#workshopSelect').select2({
        placeholder: 'Select an workshop',
        allowClear: true
      });
    }

    if ($('#appointmentSelect').length) {
      $('#appointmentSelect').select2({
        placeholder: 'Select an appointment slotr',
        allowClear: true
      });
    }

    // Cleanup function to destroy Select2 instances before component unmount
    return () => {
      if ($('#workshopSelect').data('select2')) {
        $('#workshopSelect').select2('destroy');
      }
      if ($('#appointmentSelect').data('select2')) {
        $('#appointmentSelect').select2('destroy');
      }
    };
  }, []);

  useEffect(() => {
    fetchWorkshopsByInstructor();
  }, []);

  const fetchWorkshopsByInstructor = () => {
    fetch(`${API_URL}/workshop/get-workshop_for_website_by_workshop_instructor_id/?workshop_instructor_id=${instructorId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const workshopOptions = data.data.Workshop.map(workshop => ({
            id: workshop.id,
            text: workshop.name,
          }));
          setWorkshops(workshopOptions);
        } else {
          setWorkshops([]);
        }
      })
      .catch(error => {
        console.error('Error fetching workshops:', error);
        setWorkshops([]);
      });
  };

  const handleWorkshopChange = (e) => {
    const workshopId = e.target.value;
    setSelectedWorkshop(workshopId);
    fetchAppointmentSlotsByWorkshop(workshopId);  // Fetch appointment slots for the selected workshop
  };


  const [appointmentSlots, setAppointmentSlots] = useState([]);

  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
  const fetchAppointmentSlotsByWorkshop = (workshopId) => {
    fetch(`${API_URL}/workshop_slot/get-workshop_slot_by_workshop_id/?WorkshopSlot_workshop_id=${workshopId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const slotOptions = data.data.WorkshopSlot.map(slot => ({
            id: slot.id,
            date: slot.date,         // Keep date in raw format
            startTime: slot.startTime, // Keep start time in raw format
            endTime: slot.endTime,    // Keep end time in raw format
          }));
          setAppointmentSlots(slotOptions); // Set slot options as is
        } else {
          setAppointmentSlots([]); // Set empty if no data
        }
      })
      .catch(error => {
        console.error('Error fetching appointment slots:', error);
        setAppointmentSlots([]);
      });
  };

  const [appointments, setAppointments] = useState([]);
  const [totalCollection, setTotalCollection] = useState(0);

  const applyAppointment = () => {
    if (!selectedWorkshop || !selectedAppointmentSlot) {
      alert('Please select both workshop and appointment slot.');
      return;
    }

    const apiUrl = `${API_URL}/appointment/workshops/appointments/by-slot/?workshop_slot_id=${selectedAppointmentSlot}&workshop_id=${selectedWorkshop}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Update the state with the appointments data
          setAppointments(data.data.Appointments);
          setTotalCollection(data.data.total_collection); 
          // alert('Appointments retrieved successfully!');
        } else {
          alert('Failed to retrieve appointments.');
        }
      })
      .catch((error) => {
        console.error('Error fetching appointments:', error);
        alert('Error fetching appointments.');
      });
  };

  const formatTime12Hour = (time) => {
    let [hour, minute] = time.split(':');
    let period = 'AM';

    hour = parseInt(hour, 10);

    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) {
        hour -= 12;
      }
    } else if (hour === 0) {
      hour = 12;
    }

    return `${hour}:${minute} ${period}`;
  };


  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };


  return (
    <div>
      <Header></Header>
      {modalGenerateInvoiceIsOpen && (
                <GenerateInvoice onClose={closeGenerateInvoice} slotId={selectedSlotId} />
            )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Invoice</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="col-12 col-md-12 col-lg-12 row">
              <div className="child col-md-4" style={{ padding: "5px" }}>
                <Select2
                  id="workshopSelect"
                  data={workshops}
                  value={selectedWorkshop}
                  onChange={handleWorkshopChange}
                  options={{
                    placeholder: 'Select a workshop',
                    allowClear: true,
                  }}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="child col-md-4" style={{ padding: "5px" }}>
                <Select2
                  id="appointmentSelect"
                  data={appointmentSlots.map(slot => {
                    // Format date and time
                    const formattedDate = new Date(slot.date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    });
                    const startTime = new Date(`1970-01-01T${slot.startTime}`).toLocaleTimeString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                    });
                    const endTime = new Date(`1970-01-01T${slot.endTime}`).toLocaleTimeString('en-US', {
                      hour: 'numeric',
                      minute: '2-digit',
                    });

                    // Return formatted options for Select2
                    return {
                      id: slot.id,
                      text: `${formattedDate} | ${startTime} - ${endTime}`, // Display in the dropdown
                    };
                  })}
                  value={selectedAppointmentSlot} // Selected value for controlled component
                  onChange={(e) => setSelectedAppointmentSlot(e.target.value)} // Handle change
                  options={{
                    placeholder: 'Select an appointment slot',
                    allowClear: true,
                  }}
                  style={{ width: '100%' }} // Full-width dropdown
                />
              </div>
              <div class="col-md-4">
                <div class="child">
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      class=" btn-round"
                      onClick={applyAppointment}

                    >
                      <i class="bi bi-person-add"></i> Apply
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Title</th>
                    <th scope="col">Slot With Appointment Date</th>
                    <th scope="col">Customer Details</th>
                    <th scope="col">Payment Amount</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {appointments.length > 0 ? (
                    appointments.map((appointment, index) => {
                      const { startTime, endTime } = appointment.workshopSlot;
                      return (
                        <tr key={appointment.id}>
                          {/* Sr.No */}
                          <td>{index + 1}</td>

                          {/* Title (Workshop name) */}
                          <td>{appointment.workshopSlot.workshop.name}</td>

                          {/* Slot with Appointment Date (formatted to 12-hour format) */}
                          <td>
                            {formatCreatedAt(appointment.workshopSlot.date)}{' '}<br />
                            {`${formatTime12Hour(startTime)} - ${formatTime12Hour(endTime)}`}
                          </td>

                          {/* Customer Details */}
                          <td>
                            Name: {appointment.user.name} <br />
                            Email: {appointment.user.email} <br />
                            Mobile: {appointment.user.mobileNumber || '--'}
                          </td>

                          {/* Payment Amount */}
                          <td>{appointment.price || '--'}</td>
                        </tr>
                      );
                    })
                  ) : (

                    <td colSpan="12" style={{ textAlign: 'center', fontSize: '20px' }}>No appointments available</td>

                  )}
                </tbody>
                {appointments.length > 0 && (
                  <tfoot>
                    <tr>
                      <td colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                        Total Collection:
                      </td>
                      <td style={{ fontWeight: 'bold' }}>{totalCollection}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" style={{ textAlign: 'right' }}>
                        <button
                          className="btn-round"
                          onClick={() => openGenerateInvoice(appointments[0].workshopSlot.id)}
                        >
                          Generate Invoice
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>


            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {appointments.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>

  )
}

export default Invoice

